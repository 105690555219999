import React from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useRQuery from '@hooks/useRQuery';
import { getAuthHeader } from '@config/functions/helperFunctions';
import { selectProfile } from '@redux/profileSlice';
import { bpaUrls } from '@config/routes';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link } from 'react-router-dom';

const { modelAssetsFromStageUrls } = bpaUrls;

export default function ShowAssetsPopup({ open, setOpen, stageId }) {
  const user = useSelector(selectProfile);

  const { data, isLoading, isError } = useRQuery({
    key: [`${stageId}-assets`, stageId, user.token, user?.actAs],
    url: modelAssetsFromStageUrls.detail(stageId),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: Boolean(open && stageId) },
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog fullWidth maxWidth='sm' open={open} onClose={() => null}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <DialogTitle sx={{ pointerEvents: 'none' }}>Assets</DialogTitle>

        <IconButton onClick={handleClose} sx={{ pr: 3 }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent
        sx={{
          pt: '4px',
        }}
      >
        {isError ? (
          <Typography>Failed to fetch data...</Typography>
        ) : isLoading || !data ? (
          <Typography>loading...</Typography>
        ) : data?.length ? (
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              size='medium'
              aria-label='simple table'
            >
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Link</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((stage) =>
                  stage.items.map((asset) => (
                    <TableRow key={asset.asset_id}>
                      <TableCell component='th' scope='row'>
                        {stage.type}
                      </TableCell>
                      <TableCell>
                        {console.log('asset', asset)}
                        <Link
                          to={`${asset?.details?.asset_model?.frontend_route}${asset.asset_id}`}
                          target={'_blank'}
                          rel={'noopener noreferrer'}
                        >
                          {asset?.details?.asset?.name}
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography>No assets</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
}
